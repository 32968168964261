import React from "react"

const ThirdSection = () => {
    return (
        <section className="third" id="por-que">
            {/* <div className="flexbox"> */}
            <div className="title-box">
                <p className="white-font">
                    Não deixe mais passar em branco datas importantes
                    <br />
                    para <span>seus amigos queridos.</span>
                </p>
            </div>
            <div className="row">
                <p className="white-font">
                    Você não precisa mais se culpar por{" "}
                    <span className="font-bold">
                        ter esquecido uma data importante
                    </span>{" "}
                    ou por não ter acertado no presente.
                </p>
                <p className="white-font">
                    Com o Gifhty{" "}
                    <span className="font-bold">
                        você evita estes constrangimentos
                    </span>{" "}
                    e ainda garante o melhor presente de modo fácil, rápido e
                    seguro.
                </p>
            </div>
            {/* </div> */}
        </section>
    )
}

export default ThirdSection
